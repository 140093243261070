<template>
  <div ref="animationViewer" style="" :class="'animViewer_'+format">
    <div v-if="loading && !noAnimation" class="loader_container">
      <span class="loading_spinner" style="--color:var(--color2); position: relative;"></span>
    </div>
    <div v-if="noAnimation == false" :style="style" :class="{ pointer: clickable }" @mouseover="mouseOver = true" style="width:100%;height: 100%;"
      @mouseleave="mouseOver = false" ref="lavContainer"></div>
    <div v-if="noAnimation == true" class="noAnimation">
      {{ $t('CreativeSpace.chooseAnim') }}
    </div>
  </div>
</template>

<script>
// LIBS
import lottie from "lottie-web";

//Lotties
import lottie_loader from "../../assets/lotties/lottie_loader.json";
export default {
  name: "animationViewer",
  props: {
    noAnimation: {
      default: false
    },
    animation: {
      default: undefined
    },
    clickable: {
      default: false,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true,
    },
    loop : {
      type: Boolean,
      required: false,
      default: true,
    },
    format: {
      default: 'horizontal'
    }
  },
  emits: [],
  data: function () {
    return {
      name: "lottie-animation",
      rendererSettings: {
        scaleMode: "centerCrop",
        clearCanvas: true,
        progressiveLoad: false,
        hideOnTransparent: true,
        preserveAspectRatio: "xMinYMin slice",
      },
      anim: null,
      style: "border-radius: var(--border_radius)",
      animation_object: null,
      features: [],
      mouseOver: false,
      modificationPending: false,
      loading : false
    };
  },
  watch: {

    noAnimation: {
      handler(val, oldVal) {
        if (oldVal && !val) {
          this.$nextTick(() => {   // !Important: without nextTick, lottieLoader try to load animation before div lavContainer is available
            this.initAnimation()
          })

        } else if (!oldVal && val) {
          this.$nextTick(() => {
            this.destroyAnim()
          })
        }
      },
      deep: true

    },
    mouseOver(val) {
      if (!this.autoplay && this.animation != undefined ) {
        if (val) {
          this.anim.setCurrentRawFrameValue(0)
          this.anim.play();
        } else {
          this.anim.stop()
          this.setFixedFrame()
        }
      }
    },
  },
  mounted() {  // ONLY MOUNTED IS USED NOW, REST OF CODE CAN BE DELETE IN FUTUR
    this.initAnimation()
  },
  beforeUnmount() {
    this.destroyAnim()
    this.$refs.animationViewer.remove()
  },
  methods: {
    initAnimation() {
      if (this.animation == undefined || this.animation.anim_data == undefined) {
        // If no animation id and animation is given, load LOTTIE LOADER to signal the customer to wait
        /*
        this.anim = lottie.loadAnimation({
          container: this.$refs.lavContainer,
          renderer: "svg",
          animationData: lottie_loader,
          loop: this.loop,
          autoplay: true,
          style : {'max-wdith' : '200px'}
        })
        */
        this.loading = true
      } else {
        this.loading = false
        // Animation is given so just load animation data in lottie 
        this.anim = lottie.loadAnimation({
          container: this.$refs.lavContainer,
          renderer: "svg",
          loop: this.loop,
          autoplay: this.autoplay,
          animationData: this.animation.anim_data,
          rendererSettings: this.rendererSettings,
        })

        this.$nextTick(() => {
          if (!this.autoplay) {
            this.setFixedFrame()
          }
        })
      }
    },
    setFixedFrame() {
      if (this.animation != undefined) {
        if (this.animation.anim_type == 0) {
          this.anim.setCurrentRawFrameValue(this.anim.totalFrames / 8)
        }
        else if (this.animation.anim_type == 1) {
          this.anim.setCurrentRawFrameValue(this.anim.totalFrames / 5.5)
        }
        else {
          this.anim.setCurrentRawFrameValue(this.anim.totalFrames / 2)
        }
      }
    },
    destroyAnim() {
      if (this.anim != null) {
        this.anim.destroy()
      }
    }
  }


}
</script>

<style scoped>

.loader_container{
  position: absolute;
  width:100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}

.noAnimation {
  width: 100%;
  height: 100%;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  border: 2px dashed #606E81;
  box-sizing: border-box;
  border-radius: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.animViewer_horizontal{
  aspect-ratio: 16/9;
}

.animViewer_vertical{
  aspect-ratio: 9/16;
}

.animViewer_square{
  aspect-ratio: 1/1;
}
</style>
